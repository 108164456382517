import {
    getList,
    exportOrder, //导出
    salesReturn
} from './api'
import Util from "@/utils/utils";
import cancelModal from "./components/cancelModal";

export default {
    name: 'scaleOrderList',
    components: {
        cancelModal,

    },
    data() {
        return {
            breadcrumbData: [{
                path: "/index",
                name: "index",
                title: "首页",
            },
            {
                path: "/channelReturns/distributorReturnList",
                name: "",
                title: "退货明细",
            },
            ],
            navList: [],
            page: {
                pageNo: 1,
                pageSize: 10
            },
            goodsList: [],
            invalidList: [], //批量失效
            insearchOBJ: [],
            paramList: [],
            searchzz: false,
            loadings: false,
            // 暂无数据
            NoDate: false,
            nolistImg: require("@/assets/order/noList.png"),
            // 暂无更能多数据
            noMore: false,
            // 查看更多
            loadMores: true,
            goodsNmuber: 0,
            pageLoadFlag: true,
            requestdata: {},
            isLoading: false,
            visibleCancel:false,
            cancelDocumentNum:''
        }
    },
    filters: {
        formatDate: function (value) {
            let date = new Date(value);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? ('0' + MM) : MM;
            let d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            let h = date.getHours();
            h = h < 10 ? ('0' + h) : h;
            let m = date.getMinutes();
            m = m < 10 ? ('0' + m) : m;
            let s = date.getSeconds();
            s = s < 10 ? ('0' + s) : s;
            return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
        }
    },
    beforeCreate() {
        // 添加背景色
        document.querySelector('body').setAttribute('style', 'background-color:#F7F7F7')
    },
    beforeDestroy() {
        window.removeEventListener("storage", function (e) {
        });
        document.querySelector('body').setAttribute('style', '')
    },
    mounted() {

    },
    methods: {
        openWindow(documentNum) {
            this.cancelDocumentNum = documentNum
            this.visibleCancel = true
        },
        getReason(e) {
            if(!e) {
                this.$message.warning('请输入取消原因')
                return
            }
            let data = {
                cisCode:this.$store.state.user.userInfo.customer.cisCode,
                documentNum:this.cancelDocumentNum,
                message:e,
            }
            this.pageLoadFlag = true
            salesReturn(data).then(res=>{
                let data = JSON.parse(res.data)
                if(data.code == '0') {
                    this.$message.success('取消成功')
                } else {
                    this.$message.success(data.msg)
                }
                this.getCommodityList()
                this.pageLoadFlag = false
            })
        },
        // 取消退货
        getsalesReturn() {

        },
        // 跳转出库详情
        outflow(id) {
            let routeUrl = this.$router.resolve({
                path: '/channelReturns/distributorReturnList/distributordetail',
                query: {
                    id,
                }
            });
            window.open(routeUrl.href, "_blank");
        },

        // 查看进度
        toProcess(id) {
            let routeUrl = this.$router.resolve({
                path: '/channelReturns/distributorReturnList/distributorprocess',
                query: {
                    id,
                }
            });
            window.open(routeUrl.href, "_blank");
        },

        // 表单筛选
        inputSearch(data) {
            this.paramList = data[1].paramList;
            this.page.pageNo = 1;
            this.goodsList = []
            this.pageLoadFlag = true;
            this.getCommodityList();
        },
        // 全选
        chooseAll(e) {
            if (e.target.checked) {
                for (let i = 0; i < this.goodsList.length; i++) {
                    this.goodsList[i].checked = true;
                }
            } else {
                for (let i = 0; i < this.goodsList.length; i++) {
                    this.goodsList[i].checked = false;
                }
            }
            this.$forceUpdate();
        },

        // 导出
        exportClick() {
            let data = JSON.parse(JSON.stringify(this.requestdata));
            data.page.pageSize = 300000;
            data.page.pageNo = 1;
            this.isLoading = true;
            exportOrder(data).then(res => {
                this.isLoading = false;
                Util.blobToJson(res.data).then(content => {
                    if (content && content.msg == 'success') {
                        this.$message.success('下载成功，请到下载中心查看!')
                    }
                }).catch(err => {
                    Util.downloadFile(res.data, '销售订单.xls')
                }).finally(() => {
                    this.expLoading = false;
                })
            })
        },

        // 商品选择
        chooseItem(data, index, e) {
            this.goodsList[index].checked = e.target.checked;
            var pushY = this.invalidList.indexOf(data);
            if (pushY == -1) {
                this.invalidList.push(data);
            } else {
                this.invalidList.splice(pushY, 1);
            }
            this.$forceUpdate()
        },

        // 商品列表
        getCommodityList() {
            let data = {
                cisCode: this.$store.state.user.userInfo.customer.cisCode,
                terms: {
                    documentNum: '',
                    supplierCode: '',
                    supplierName: '',
                    warehouseCode: '',
                    warehouseName: '',
                    startDate: '',
                    endDate: '',
                    paramList: this.paramList
                },
                page: this.page
            }
            this.requestdata = data;

            this.pageLoadFlag = true;
            getList(data).then(res => {
                this.pageLoadFlag = false;
                var realDate = JSON.parse(res.data);
                this.goodsNmuber = realDate.page.totalCount;
                this.goodsNmuber = realDate.page.totalCount;
                this.loadings = false;
                this.goodsList = realDate.data
                for (let i = 0; i < this.goodsList.length; i++) {
                    this.goodsList[i].checked = false;
                }
            })
        },
        // 加载更多
        loadMore() {
            this.loadings = true;
            this.page.pageNo++;
            this.pageLoadFlag = true;
            let data = {
                cisCode: this.$store.state.user.userInfo.customer.cisCode,
                terms: {
                    documentNum: '',
                    supplierCode: '',
                    supplierName: '',
                    warehouseCode: '',
                    warehouseName: '',
                    startDate: '',
                    endDate: '',
                    paramList: this.paramList
                },
                page: this.page
            }
            this.requestdata = data;
            this.pageLoadFlag = true;
            getList(data).then(res => {
                this.pageLoadFlag = false;
                this.loadings = false
                var realDate = JSON.parse(res.data);
                this.goodsList = this.goodsList.concat(realDate.data);
                for (let i = 0; i < this.goodsList.length; i++) {
                    this.goodsList[i].checked = false;
                }
            })
        },
    }
}